nav#bb {
    height: 5rem;
    width: 100vw;
    background: var(--color-black);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    border-bottom: 2px solid white;
}

/* Shows on smaller screens */

.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 6rem;
    display: block;
}

.nav__links#bb {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}

.nav__links#bb li a {
    transition: var(--transition);
    color: var(--color-grey);
    font-weight: 600;
}

.nav__links#bb li a svg {
    transition: var(--transition);
    font-weight: 600;
}

.nav__links#bb li a:hover, .nav__links#bb li a svg:hover {
    color: var(--color-darkgrey);
}

.active-nav {
    position: relative;
}

.active-nav:after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background: var(--color-darkgrey);
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.9rem;
}

/* Media Queries (smaller screens) */

@media screen and (max-width: 1024px) {
    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav__toggle-btn svg {
        color: var(--color-black);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
    }

    .active-nav, .active-nav::after {
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        box-sizing: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX(90deg);
        opacity: 0;
        transform-origin: top;
    }

    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }

   .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }
       .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }

    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }

    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }

    .nav__links li a {
        background-color: var(--color-darkgrey);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }

    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }
}
