.members__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
}

.card.member {
    padding: 1rem;
}

.member__img {
    width: 7rem;
    height: 7rem;
    border-radius: 5rem;
    border: 4px solid var(--color-secondary);
    overflow: hidden;
    background:var(--color-grey);
    margin:auto;
    transition: var(--transition);
}

.member h3 {
    margin-top: 2rem;
    transition: var(--transition);
}

.member h4 {
    margin-top: 0.5rem;
    transition: var(--transition);
}

.member p {
    margin-top: 0.5rem;
    color: var(--color-white);
    transition: var(--transition);
}

.member p#desc {
    font-weight: 500;
    color: var(--color-grey)
}

.members_docs {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.members_docs a {
    font-size: 1.25rem;
    width: 3.1rem;
    background: var(--color-darkgrey);
    padding: 0.8rem;
    border-radius: 0.7rem;
    transition: var(--transition);
}

.member:hover .member__img {
    background: var(--color-primary);
    border: 4px solid var(--color-black);
}

.member:hover h3, .member:hover h4, .member:hover p, .member:hover p#desc {
    color: var(--color-black);
}

/* Media Queries for Medium Screens */

@media screen and (max-width: 1024px) {
    .members__container {
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
    }
}

/* Media Queries for Small Screens */

@media screen and (max-width: 600px) {
    .members__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .card.member {
        width: 90%;
        margin-inline: auto;
    }
}