.blackbird__header {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
    border-bottom: solid 3px var(--color-white);
    background-size: 100%;
    background-color: var(--color-black);
    background-blend-mode: multiply;
}

.blackbird__header-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
    z-index:5;
    margin-left: 100vh;
}

.blackbird__header-center {
    margin-top: -3rem;
    text-align: center;
}

.blackbird__header-center h1 {
    color: var(--color-white);
}

.blackbird__header-center p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
    color: var(--color-white);
}

.blackbird__header-center .btn.lg {
    margin-right: 3rem;
    background-color: var(--color-grey);
    color:var(--color-black)
}

.bouter-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.binner-heading {
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    font-weight: 900;
    font-size: 3rem;
    text-transform: uppercase;
    overflow: hidden;
}

.binner-heading span {
    position: relative;
    color: var(--color-black);
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: var(--color-black);
}

/* Footer */

footer#bb {
    background: var(--color-black);
    font-size: 0.9rem;
    color: var(--color-white);
    margin-top: 0;
    border: 5px black;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__copyright#bb {
    color: var(--color-grey);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-white);
    margin-top: 5rem;
}

@media screen and (max-width: 1024px) {
    .blackbird__header {
        height: fit-content;
        padding: 12rem 0;
    }

    .blackbird__header-container {
        gap: 0;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}

/* Media Queries for Smaller Screens */

@media screen and (max-width: 600px) {

    .blackbird__header {
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
    }

    .blackbird__header-container {
        grid-template-columns: 1fr;
        margin-left: 1rem;
    }

    footer {
        margin-top: 7rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }
}