.notfound__container {
    text-align: center;
    padding-top: 8rem;
}

.notfound__container .btn {
    margin-top: 2rem;
    display: inline-block;
}

.notfound__container h2 {
    color: var(--color-grey);
}