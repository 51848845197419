.head {
    color: var(--color-grey);
    text-align: center;
    margin-top: 5rem;
    margin-bottom: none;
    text-transform: capitalize;
}

.platinum {
    margin-top:2rem;
}

.platinum__container {
    grid-template-columns: repeat(3, 1fr);
}

.gold__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.platinum__container article, .gold__container article {
    border: 1rem solid transparent;
    transition: var(--transition);
}

.platinum__container article:hover, .gold__container article:hover {
    border-color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .media__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .media__container article {
        border: 0;
    }
}

@media screen and (max-width: 600px) {
    .media__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}